import React from 'react'
const NotFound = () => {
    return (
        <div  className="fixing" style={{position:"relative"}}>
            <h1 style={{position:"absolute",top:"25%",left:"50%",transform:"translateX(-50%)",fontSize:"80px"}}>Not Found</h1>
        </div>
    )
}

export default NotFound
